@import "src/@fuse/scss/fuse";

@mixin ss-dialog-theme($theme) {

  .ss-dialog-container .mat-dialog-container {
    padding: 0;
  }

  .dialog {
    &__header {
      padding: 16px;

      &__close-icon {
        float: right;
        font-size: 24px;
        position: relative;
        top: -4px;
        z-index: 2;
        cursor: pointer;
        mat-icon {
          vertical-align: middle;
          margin-right: 4px;
        }
      }

      &__title {
        margin: 0;
      }
    }

    &__content {
      margin: 0;
      padding: 24px 24px 8px 24px;

      &__form-field {
        font-family: Muli;
        font-size: 16px;
        width: 100%;
        margin-bottom: 0.4em;
      }
    }

    &__actions {
      padding: 0 24px;
      justify-content: flex-end;
      margin-bottom: 0;
      min-height: 0;

      &__action {
        border: none;
        text-transform: uppercase;
        font-size: 14px;
        cursor: pointer;
        padding-bottom: 8px;
        margin-left: 8px;
        font-family: Muli;
        font-weight: bold;

        &.green-button {
          color: #00A311;
        }

        &.red-button {
          color: #A3001B
        }
      }
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #8E8E8C;
  }
}

