@import "../../../@fuse/scss/fuse";

@mixin ss-table-theme($theme) {

  .ss-table {
    .img-container {
      width: 32px;
      height: 32px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      background-size: cover;
    }

    .mat-cell.bold {
      font-weight: 700;
    }

    mat-row {
      position: relative;
      cursor: pointer;
      height: 51px;
    }
  }

}